import { render, staticRenderFns } from "./People.vue?vue&type=template&id=5f0d2ee0&scoped=true&"
import script from "./People.vue?vue&type=script&lang=js&"
export * from "./People.vue?vue&type=script&lang=js&"
import style0 from "./People.vue?vue&type=style&index=0&id=5f0d2ee0&prod&lang=scss&"
import style1 from "./People.vue?vue&type=style&index=1&id=5f0d2ee0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f0d2ee0",
  null
  
)

export default component.exports